<template>
  <UiFlexbox
    full-width
    direction="column"
    align="center"
    justify="center"
    class="staking-pool-card"
    :class="{
      'is-scroll-network': isScrollNetwork,
      'is-buy': deorderType === 'BUY',
      'is-sell': deorderType === 'SELL',
    }"
  >
    <UiFlexbox v-if="!isHODLPool" full-width direction="column">
      <UiFlexbox justify="space-between" align="center">
        <UiFlexbox direction="column">
          <ui-text variant="body2" :color="buyOrSell">
            {{ deorderType }}
          </ui-text>
        </UiFlexbox>
        <ui-text variant="body3" color="textSecondary">
          {{ strikePrice.formatted }}
          <ui-divider direction="vertical" :spacing="4" color="textSecondary" />
          {{ exerciseTimestamp.formatted }}
        </ui-text>
      </UiFlexbox>
      <UiFlexbox full-width class="staking-pool-card__header">
        <ui-text variant="body4" :color="buyOrSell">
          {{ getOriginalSymbol(token.type) }}
        </ui-text>
      </UiFlexbox>
    </UiFlexbox>
    <UiFlexbox
      v-else
      full-width
      direction="column"
      class="hodl-pool-card__header"
    >
      <ui-text variant="h5" :color="buyOrSell">
        {{ getOriginalSymbol(token.type) }}
      </ui-text>
    </UiFlexbox>
    <template v-if="!isMobileScreen">
      <UiFlexbox full-width class="vapr" align="center" gap="6">
        <ui-text variant="h4" color="textPrimary">
          {{ vapr.formatted }}
        </ui-text>
        <ui-text variant="body4" color="textSecondary">vAPR</ui-text>
      </UiFlexbox>
      <UiFlexbox
        full-width
        class="expire-status"
        :class="{ 'hide-expire-status': optionStatus === 'none' }"
      >
        <UiTag>{{ optionStatus }}</UiTag>
      </UiFlexbox>
      <UiFlexbox full-width direction="column" class="stake-info">
        <BusinessStakedInfoProgress :data="stakedInfoProgressData" />
      </UiFlexbox>
      <UiFlexbox full-width justify="space-between" class="dob-rewards">
        <UiText variant="body3">{{ getOriginalSymbol('DOB') }} Reward</UiText>
        <UiText variant="body3">{{ dobReward.formatted }}</UiText>
      </UiFlexbox>
    </template>
    <template v-else>
      <UiFlexbox
        full-width
        class="mobile__card-contents"
        justify="space-between"
      >
        <UiFlexbox direction="column" justify="space-between">
          <UiFlexbox full-width direction="column" gap="6" justify="flex-start">
            <UiText variant="h5">{{ vapr.formatted }}</UiText>
            <UiText variant="body4" color="textSecondary">vAPR</UiText>
          </UiFlexbox>
          <div :class="{ 'hide-expire-status': optionStatus === 'none' }">
            <UiTag>{{ optionStatus }}</UiTag>
          </div>
        </UiFlexbox>
        <UiFlexbox
          direction="column"
          justify="space-between"
          class="stake-info"
        >
          <BusinessStakedInfoProgress :data="stakedInfoProgressData" />
          <UiFlexbox full-width justify="space-between">
            <UiText variant="body3">
              {{ getOriginalSymbol('DOB') }} Reward
            </UiText>
            <UiText variant="button1">{{ dobReward.formatted }}</UiText>
          </UiFlexbox>
        </UiFlexbox>
      </UiFlexbox>
    </template>
    <template v-if="isHODLPool">
      <UiFlexbox full-width direction="column" gap="12">
        <UiButton
          full-width
          size="large"
          @click="handleActionClick('HODL_STAKE')"
        >
          Stake more
        </UiButton>
        <UiButton
          full-width
          size="large"
          color="secondary"
          @click="handleActionClick('HODL_UNSTAKE')"
        >
          Unstake
        </UiButton>
      </UiFlexbox>
    </template>
    <template v-else-if="canBuyNow">
      <UiFlexbox full-width direction="column" gap="12">
        <UiButton
          full-width
          size="large"
          :disabled="!canBuyNow"
          @click="handleActionClick('DEORDER')"
        >
          DeOrder more
        </UiButton>
        <UiButton
          full-width
          size="large"
          color="secondary"
          :disabled="!canUnwindNow"
          @click="handleActionClick('UNWIND')"
        >
          Unwind
        </UiButton>
      </UiFlexbox>
    </template>
    <template v-else>
      <UiFlexbox full-width direction="column" gap="24">
        <UiText variant="body3" color="textTertiary" style="text-align: center">
          {{ getOriginalSymbol('DOB') }} reward will be collected together
        </UiText>
        <UiButton
          full-width
          size="large"
          :disabled="!canCollectNow"
          @click="handleActionClick('COLLECT')"
        >
          Collect
        </UiButton>
      </UiFlexbox>
    </template>
  </UiFlexbox>
</template>
<script lang="ts" setup>
import type { UserPool } from '@base/types/sniperPools'
import type { IOptionInfo } from '@/components/business/deorder/option-info'
import { type SniperType, type HODLType } from '@deorderbook/shared'
import type { DialogActionType } from '@/components/business/dialog-action'

import { useDeOrderDialogStore } from '@/components/business/deorder-dialog'
import {
  type IHODLStakeInfo,
  useHODLStakeDialogStore,
} from '@/components/business/hodl-stake-dialog'
import {
  type IOptionCollectInfo,
  useOptionCollectDialogStore,
} from '@/components/business/option-collect-dialog'
import {
  type IOptionUnwindInfo,
  useOptionUnwindDialogStore,
} from '@/components/business/option-unwind-dialog'
import {
  type IHODLUnstakeInfo,
  useHODLUnstakeDialogStore,
} from '@/components/business/hodl-unstake-dialog'

const props = defineProps({
  pool: {
    type: Object as PropType<UserPool>,
    required: true,
    default: {} as UserPool,
  },
})
const emit = defineEmits(['onActionSelected'])

const { isMobileScreen } = useScreenSize()
const { pool } = toRefs(props)
const { isScrollNetwork } = useNetwork()
const {
  deorderType,
  buyOrSell,
  strikePrice,
  exerciseTimestamp,
  token,
  vapr,
  isHODLPool,
  optionAddress,
  dobReward,
  myStaked,
  stakedInfoProgressData,
} = usePoolPageInfo(pool)

const exerciseTimestampValue = computed(
  () => exerciseTimestamp.value.timestamp ?? '000000000',
)
const { mulDecimals } = useTokens()
const { canBuyNow, canUnwindNow, isExpiringSoon, canCollectNow } =
  useOptionStatus(exerciseTimestampValue)

const optionStatus = computed(() => {
  if (isHODLPool.value) return 'none'
  if (isExpiringSoon.value) return 'Expire Soon'
  if (canCollectNow.value) return 'Expired'
  return 'none'
})

const { setData: setDeOrderData, openDialog: openDeOrderDialog } =
  useDeOrderDialogStore()
const { setData: setHODLStakeData, openDialog: openHODLStakeDialog } =
  useHODLStakeDialogStore()
const { setData: setOptionCollectData, openDialog: openOptionCollectDialog } =
  useOptionCollectDialogStore()
const { setData: setOptionUnwindData, openDialog: openOptionUnwindDialog } =
  useOptionUnwindDialogStore()
const { setData: setHODLUnstakeData, openDialog: openHODLUnstakeDialog } =
  useHODLUnstakeDialogStore()

// eslint-disable-next-line max-lines-per-function
const handleActionClick = (action: DialogActionType) => {
  if (action === 'DEORDER') {
    setDeOrderData({
      type: deorderType.value,
      strikePrice: strikePrice.value.raw,
      exerciseTimestamp: exerciseTimestamp.value.formatted ?? '',
      address: optionAddress.value,
      apr: vapr.value.formatted,
      ...stakedInfoProgressData.value,
    } satisfies IOptionInfo)
    openDeOrderDialog()
  }
  if (action === 'HODL_STAKE') {
    setHODLStakeData({
      ...stakedInfoProgressData.value,
      apr: vapr.value.formatted,
      address: optionAddress.value,
      token: token.value.type as HODLType,
    } satisfies IHODLStakeInfo)
    openHODLStakeDialog()
  }
  if (action === 'UNWIND') {
    setOptionUnwindData({
      type: deorderType.value,
      strikePrice: strikePrice.value.raw,
      formattedExerciseTimestamp: exerciseTimestamp.value.formatted ?? '',
      address: optionAddress.value,
      sniperType: token.value.type as SniperType,
      sniperAmount: stakedInfoProgressData.value.staked.amount.toString(),
      dobReward: dobReward.value,
    } satisfies IOptionUnwindInfo)
    openOptionUnwindDialog()
  }

  if (action === 'HODL_UNSTAKE') {
    setHODLUnstakeData({
      buyOrSell: buyOrSell.value,
      hodlType: token.value.type as HODLType,
      staked: mulDecimals(
        myStaked.value.amount,
        token.value.type,
      ).value.toString(),
      dobReward: dobReward.value,
    } satisfies IHODLUnstakeInfo)
    openHODLUnstakeDialog()
  }
  if (action === 'COLLECT') {
    setOptionCollectData({
      type: deorderType.value,
      sniperType: token.value.type as SniperType,
      address: optionAddress.value,
      strikePrice: strikePrice.value.raw,
      amount: myStaked.value.amount,
      dobReward: dobReward.value,
      formattedExerciseTimestamp: exerciseTimestamp.value.formatted ?? '',
    } satisfies IOptionCollectInfo)
    openOptionCollectDialog()
  }
}
</script>
<style lang="postcss" scoped>
.staking-pool-card {
  flex-shrink: 0;
  height: fit-content;
  padding: 16px;
  border-radius: 24px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
  &.is-buy {
    background: var(--brand-color-buy-bg);
    border: 1px solid var(--brand-color-buy-stroke);
  }
  &.is-sell {
    background: var(--brand-color-sell-bg);
    border: 1px solid var(--brand-color-sell-stroke);
    &.is-scroll-network {
      background: var(--brand-color-e-hodl-bg);
      border: 1px solid var(--brand-color-e-hodl-stroke);
    }
  }

  & .card-header {
    margin-bottom: 1rem;
    @media screen and (max-width: 768px) {
      margin-bottom: 0.75rem;
    }
  }

  & .mobile__card-contents {
    height: 6.25rem;
    margin-bottom: 1.25rem;
  }

  & .vapr {
    margin-bottom: 0.25rem;
  }

  & .expire-status {
    margin-bottom: 3rem;
  }
  & .hide-expire-status {
    pointer-events: none;
    cursor: none;
    opacity: 0 !important;
  }

  & .stake-info {
    margin-bottom: 1rem;
    @media screen and (max-width: 768px) {
      width: 206px;
      margin-bottom: 0;
    }
  }

  & .dob-rewards {
    margin-bottom: 1.25rem;
  }
}

.staking-pool-card__header {
  margin-bottom: 1rem;
}

.hodl-pool-card__header {
  margin-bottom: 2rem;
}
</style>
