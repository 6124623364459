import { type IHODLUnstakeInfo } from './index'

export const useHODLUnstakeDialogStore = defineStore(
  'hodlUnstakeDialog',
  () => {
    const DEFAULT_DATA: IHODLUnstakeInfo = {
      buyOrSell: 'buy',
      hodlType: 'uHODL',
      staked: '0',
      dobReward: {
        amount: '0',
        usd: '0',
      },
    }
    const {
      value: isDialogVisible,
      setTrue: openDialog,
      setFalse: closeDialog,
    } = useBoolean(false)
    const data = ref<IHODLUnstakeInfo>(DEFAULT_DATA)

    const setData = (newData: IHODLUnstakeInfo) => {
      data.value = newData
    }

    const reset = () => {
      data.value = DEFAULT_DATA
    }

    return {
      data,
      isDialogVisible,
      openDialog,
      closeDialog,
      setData,
      reset,
    }
  },
)
